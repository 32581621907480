import React from 'react';
import { TweenMax, Elastic, Expo} from 'gsap';
import { slug } from '../components/animals';
import prefetchImages from 'prefetch-image';

export default class HeaderLetter extends React.Component{
    constructor(props){
        super(props);
        this.handlePress = this.handlePress.bind(this);
        this.targetFrame = 0;
        this.currentFrame = 0;
        this.slug = 'leopard';
        this.raf_LoopId = null;

        this.state = {
            mouseX: 0,
            mouseY: 0,
            mouseXX: 0,
            orientation: null,
            request: null
        }
    }

    componentDidMount(){

        const that = this;

        if(window.innerWidth > 1024){

            // TweenMax.to(".logo-wrapper .loader", .75, {
            //     scale: .8,
            //     yoyo: true,
            //     repeat: -1,
            //     ease: Power4.easeOut
            // });
    
            TweenMax.to(".logo-wrapper .logo-frames", 1.2, {
                scale: 1,
                opacity: 1,
                delay: 0.5,
                ease: Elastic.easeOut.config(1, 0.9)
            });

            const names = ['leopard', 'hippo', 'zebra', 'elephant'];
            let images = [];
    
            for(let i = 0; i < 4; i++){
                for (let j = 0; j < 60; j++) {
                    images.push(`${names[i]}-high/${names[i]}-${j}.png`)
                }
            }
    
            prefetchImages(images)
            .then(() => {
                // TweenMax.killTweensOf(".logo-wrapper .loader");
                // TweenMax.to(".logo-wrapper .loader", .75, {
                //     scale: 0,
                //     ease: Expo.easeInOut
                // });
    
                // TweenMax.fromTo(".logo-wrapper img", 1.2, {
                //     opacity: 1,
                //     scale: 0
                // },{
                //     scale: 1,
                //     opacity: 1,
                //     delay: 1,
                //     ease: Elastic.easeOut.config(1, 0.9)
                // });

                TweenMax.to('.animals-list-container', 0.7, {
                    x: 0,
                    ease: Expo.easeOut
                });
    
                window.addEventListener("mousemove", function(e) {
                    that.setState({
                        mouseX: e.clientX - window.innerWidth/2,
                        mouseY: e.clientY - window.innerHeight/2,
                        mouseXX: e.clientX
                    })
                });

                that.setState({
                    request: requestAnimationFrame(that.handlePress)
                });
            }); 
        } else {

            TweenMax.to(".logo-wrapper .logo-frames", 1.2, {
                scale: 1,
                opacity: 1,
                delay: 0.5,
                ease: Elastic.easeOut.config(1, 0.9)
            });

            const names = ['leopard', 'hippo', 'zebra', 'elephant'];
            let images = [];
    
            for(let i = 0; i < 4; i++){
                for (let j = 0; j < 1; j++) {
                    images.push(`${names[i]}-high/${names[i]}-${j}.png`)
                }
            }
    
            prefetchImages(images)
            .then(() => {
                // TweenMax.to(".logo-wrapper .loader", .75, {
                //     scale: 0,
                //     ease: Expo.easeInOut
                // });
    
                // TweenMax.fromTo(".logo-wrapper img", 1.2, {
                //     opacity: 1,
                //     scale: 0
                // },{
                //     scale: 1,
                //     opacity: 1,
                //     delay: 1,
                //     ease: Elastic.easeOut.config(1, 0.9)
                // });

                TweenMax.to('.animals-list-container', 0.7, {
                    x: 0,
                    delay: 0.2,
                    ease: Expo.easeOut
                });
            }); 

            this.setState({
                request: requestAnimationFrame(this.handlePress)
            });
        }
    }

    componentWillUnmount(){
        cancelAnimationFrame(this.state.request);
    }
  
    handlePress(){
        if(window.innerWidth > 1024) {
            this.setState({
                request: requestAnimationFrame(this.handlePress)
            });
            TweenMax.to(".images-wrapper", 1, {
                x: this.state.mouseX*.05,
                y: this.state.mouseY*.05,
                z: 0
            })
            TweenMax.to(".shadow", 1, {
                x: this.state.mouseX*.1,
                y: this.state.mouseY*.01,
                z: 0
            });
            this.targetFrame = 60 - Math.round( this.state.mouseXX / window.innerWidth * 60 );
            if(this.targetFrame !== this.currentFrame ) { this.currentFrame += (this.targetFrame - this.currentFrame) / 15; }
             
            document.querySelector(".logo-frames").src=`${slug}-high/${slug}-`+Math.round(this.currentFrame)+".png";   
        } else {
            this.setState({
                request: requestAnimationFrame(this.handlePress)
            });
            document.querySelector(".logo-frames").src=`${slug}-high/${slug}-`+0+".png";   
        }
    }
  
    render(){
        return(
            <React.Fragment>
                <div className="logo-wrapper">
                    <svg className="loader" viewBox="0 0 101 86.9">
                        <path d="M76 74c11.6-7.1 18.5-18.9 18.5-33.2C94.4 16.3 76.3 0 47.6 0 20 0 0 16.9 0 40.8c0 42 57.8 60.9 101 32.8-13.9 2.8-25 .4-25 .4zM47.3 17.3C64.1 17.3 74 27 74 40.8c0 13.7-10.2 23.4-26.6 23.4-15.7 0-27-8.7-27-23.4 0-14.1 11.2-23.5 26.9-23.5z" fill="#000000"/>
                    </svg>
                    <div className="images-wrapper">
                        <img src={`${slug}-high/${slug}-0.png`} className="logo-frames" alt="Letter" />
                        <img src={'shadow.png'} className="shadow" alt="Shadow" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}