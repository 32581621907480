import React from 'react';
import ReactHowler from 'react-howler'
import { canPlay } from '../components/sound-controller';
 
export let slug = 'leopard';

export default class Animals extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            animals: [
                {url:"leopard.png", slug: "leopard", active: true}, 
                {url:"hippo.png", slug: "hippo", active: false}, 
                {url:"zebra.png", slug: "zebra", active: false},
                {url:"elephant.png", slug: "elephant", active: false}
            ],
            active: 'leopard'        
        }

        this.clickAnimals = this.clickAnimals.bind(this);
    }

    componentDidMount(){
        if(!canPlay){
            window.Howler.mute(true);
        }
    }

    clickAnimals = (i) => {
        this.setState(prevState => ({
            animals: prevState.animals.map(
                (elem, key) => (key === i ? Object.assign(elem, { active: true }) : Object.assign(elem, { active: false }))
            )
        }));

        this.setState(prevState => ({
            animals: prevState.animals.map(function(elem, key){
                if(key === i){
                    slug = elem.slug;
                    return Object.assign(elem, { active: true })
                } else {
                    return Object.assign(elem, { active: false })
                }
            })
        }));
    }

    componentWillUnmount(){
        slug = 'leopard';
    }

    render(){
        return(
            <React.Fragment>
                <ReactHowler
                    src={`../${slug}.mp3`}
                    playing={true}
                    loop={false}
                    volume={0.3}
                />
                <ul className="animals-list-container">
                    {this.state.animals.map((elem, i) => (
                      <li key={i} onClick={this.clickAnimals.bind(Animals, i)} className={elem.active ? "active" : null}>
                        <img src={`${elem.slug}.png`} alt="Letter Thumbnail" />
                      </li>
                    ))}
                </ul>
            </React.Fragment>
        )
    }
}