import React from 'react'

const Title = () => {
    return(
        <div className="row expanded align-middle">
            <div className="xxlarge-12 xxlarge-offset-1 columns">
                <h2>ZIQQI<br/>Brand<br/>Guidelines</h2>
            </div>
        </div>
    )
}

export default Title
