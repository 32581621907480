import React from 'react';
import SubTitle from "../components/sub-title";
import { TweenMax, Expo } from 'gsap';

export default class BrandPhilosophy extends React.Component{

    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.offsetTop = 0;
        this.inViewport = false;
        this.grid = ["grid-01.jpg", "grid-02.jpg", "grid-03.jpg", "grid-04.jpg"];
    }

    componentDidMount(){
        this.offsetTop = document.querySelector(".brand-philosophy-grid").getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);

        TweenMax.set(".brand-philosophy-grid li .element-cover", {
            scale: 1.3
        })
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/3 && !this.inViewport){
            this.inViewport = true;
            TweenMax.staggerTo(".brand-philosophy-grid li .element-cover", 1.4, {
                scale: 1, 
                ease: Expo.easeOut
            }, 0.1)
        }
    }
    
    render(){
        return(
            <section className="brand-philosophy" data-target data-value={this.props.value} data-intersect data-intersect-color="white">
                <div className="row expanded align-center">
                    <div className="xxlarge-14 columns">
                        <div className="xxlarge-9 xxlarge-offset-3 small-10 xsmall-14 xsmall-offset-0">
                            <SubTitle content="Everyone in Africa must have easy access to every consumer choice." />
                            <SubTitle content="Every shopping experience must be fun and intuitive." />
                            <SubTitle content="Every product must be delivered swiftly and at the buyer’s convenience." />
                        </div>
                        <ul className="brand-philosophy-grid">
                            {this.grid.map((elem, i) => (
                                <li key={i}>
                                    <div className="brand-philosophy-grid-image">
                                        <div className="block-bg-cover">
                                            <img className="element-cover" src={elem} alt="Brand Philoshophy"/>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="xxlarge-9 xxlarge-offset-3 medium-10 xsmall-14 xsmall-offset-0">
                            <SubTitle content="ZIQQI is a brand for the people; signifying the Lowest Prices, Highest Quality, and the Best Service." />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
