import React from 'react'
import SubTitle from "../components/sub-title"
import Text from "../components/text"
import { TweenMax, Expo } from 'gsap';


export default class BrandSymbol extends React.Component{

    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.offsetTop = 0;
        this.inViewport = false;
    }

    componentDidMount(){
        this.offsetTop = document.querySelector(".brand-symbol-grid").getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);

        TweenMax.set(".brand-symbol-grid .element-cover", {
            scale: 1.3
        })
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/6 && !this.inViewport){
            this.inViewport = true;
            TweenMax.to(".brand-symbol-grid .element-cover", 1.4, {
                scale: 1, 
                ease: Expo.easeOut
            })
        }
    }

    render(){
        return(
            <section className="brand-symbol" data-target data-value={this.props.value}>
                <div className="brand-symbol-grid" data-intersect data-intersect-color="white">
                    <div className="block-bg-cover">
                        <img className="element-cover" src="symbol-grid.png" alt="ZIQQI Brand Grid Symbol"/>
                    </div>
                </div>
                <div className="brand-symbol-pathlines" data-intersect data-intersect-color="black-and-white">
                    <div className="row expanded align-middle">
                        <div className="xxlarge-6 xxlarge-offset-2 medium-7 medium-offset-1 small-14 columns">
                            <SubTitle content="Organic shapes for a brand that feels close to people." />
                            <Text content="Taking inspiration from natural shapes ailing from Africa and presenting them in a modern context, we establish our values at first glance — we are familiar, yet exciting and new." />
                        </div>
                        <div className="xxlarge-6 xxlarge-offset-1 small-14 columns">
                            <div className="brand-symbol-pathlines-container">
                                <div className="block-bg-cover">
                                    <img className="element-cover" src="symbol-path.png" alt="ZIQQI Brand Symbol"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
