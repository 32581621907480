import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubMenu from "../components/sub-menu"
import BrandLogo from "../components/brand-logo"
import ZiqqiMainBrand from "../images/ziqqi-brand.svg";
import ZiqqiMainBrandArabic from "../images/ziqqi-brand-arabic.svg";
import ZiqqiMainBrandURL from "../images/ziqqi-brand-url.svg";
import ZiqqiMainBrandURLArabic from "../images/ziqqi-brand-url-arabic.svg";
import ScrollHelper from "../components/scrollHelper"
import HeaderLetter from "../components/header-letter"
import Animals from "../components/animals"
import Title from "../components/title"
import BrandIntro from "../components/brand-intro"
import BrandVideos from "../components/brand-videos"
import Fullscreen from "../components/fullscreen"
import BrandPhilosophy from "../components/brand-philosophy"
import BrandSymbol from "../components/brand-symbol"
import ColorPalette from "../components/color-palette"
import Button from "../components/button"
import Typography from "../components/typography"
import ZiqqiWallet from "../components/ziqqi-wallet"
import PreFooter from "../components/pre-footer"
import Footer from "../components/footer"

const BrandHeader = () => {
  return(
    <section className="brand-header">
      <ScrollHelper />
      <Button name="Download the kit" caption="Includes the brand guidelines and all the variations of the logo." href="/" class="ziqqi-header ziqqi-button green-version"></Button>
      <HeaderLetter />
      <Animals />
      <Title />
    </section>
  )
}

const BrandLogoContainer = () => {
  return(
    <React.Fragment>
      <section data-target data-value="Logo">
        <div data-intersect data-intersect-color="white">
          <BrandLogo title="Main Brand — Positive" pathSvg={<ZiqqiMainBrand/>} background="#231F20" color="#ffffff" classButton="green-version" mainClass="big-brand main-brand-positive-ziqqi"/>
        </div>
        <div data-intersect data-intersect-color="black">
          <BrandLogo title="Main Brand — Positive Arabic version" pathSvg={<ZiqqiMainBrandArabic/>} background="#5FFF80" color="#000000" classButton="black-version" mainClass="big-brand"/>
        </div>
        <article className="brand-logo">
          <div className="row expanded align-center align-middle">
            <div className="xxlarge-16 columns">
              <div className="row expanded xxlarge-up-2 xsmall-up-1">
                <div className="columns nopadd" data-intersect data-intersect-color="white">
                  <BrandLogo title="Brand with url — Negative" pathSvg={<ZiqqiMainBrandURL/>} background="#231F20" color="#ffffff" classButton="white-version" mainClass="small-brand small-with-url-negative"/>
                </div>
                <div className="columns nopadd">
                  <BrandLogo title="Brand with url — Negative Arabic version" pathSvg={<ZiqqiMainBrandURLArabic/>} background="#f6f6f6" color="#000000" classButton="black-white-version" mainClass="small-brand small-with-url-arabic-negative"/>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </React.Fragment>
  )
}

const subMenuList = ["Introduction", "Videos", "Philosophy", "Symbol", "Logo", "Colors", "Typography", "ZIQQI Wallet"];

const subBrands = [
  {
    url: '/ziqqi-mart/',
    imageURL: 'ziqqi-mart-fullscreen-02.jpg',
    title: 'ZIQQI Mart',
    text: 'Allows physical or virtual stores to affiliate with and get a commission on every sale.'
  },
  {
    url: '/ziqqi-fresh/',
    imageURL: 'ziqqi-fresh-fullscreen-01.jpg',
    title: 'ZIQQI Fresh',
    text: 'Supplies locally sourced, all organic fruits and vegetables, meats, fish and even dairy products.'
  }
]

const palettesGroup = [
  {
    palette: [
      {
        class: 'main',
        color: 'ZIQQI Green',
        background: '#5fff80',
        colorElements: '#000000',
        properties: [
          {
            rgb: '95 / 255 / 132'
          },
          {
            hex: '#5FFF80',
          },
          {
            cmyk: '49 / 0 / 47 /0',
          },
          {
            pms: '353',
          },
          {
            embroidery: 'Madera 1702',
          },
          {
            colored: '3M Scotchcal 7725-406'
          }
        ]
      },
      {
        color: 'Black',
        background: '#000000',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '0 / 0 / 0',
          },
          {
            cmyk: '0 / 0 / 0 / 100'
          }
        ]
      }
    ]
  },
  {
    palette: [
      {
        class: 'secondary',
        color: 'Park Green',
        background: '#9DC69F',
        colorElements: '#000000',
        properties: [
          {
            rgb: '173 / 214 / 175',
          },
          {
            cmyk: '19 / 0 / 18 / 16'
          }
        ]
      },
      {
        color: 'Powder Green',
        background: '#CAE4C0',
        colorElements: '#000000',
        properties: [
          {
            rgb: '218 / 244 / 208',
          },
          {
            cmyk: '11 / 0 / 15 / 4'
          }
        ]
      },
      {
        color: 'Pistachio',
        background: '#CDD9AC',
        colorElements: '#000000',
        properties: [
          {
            rgb: '221 / 233 / 187',
          },
          {
            cmyk: '5 / 20 / 0 / 9'
          }
        ]
      },
      {
        color: 'Sorbet Yellow',
        background: '#EBE88A',
        colorElements: '#000000',
        properties: [
          {
            rgb: '252 / 248 / 154'
          },
          {
            cmyk: '0 / 2 / 39 / 1'
          }
        ]
      },
      {
        color: 'China White',
        background: '#FFFFB7',
        colorElements: '#000000',
        properties: [
          {
            rgb: '255 / 255 / 199',
          },
          {
            cmyk: '0 / 0 / 22 / 0'
          }
        ]
      },
      {
        color: 'Mist',
        background: '#FFDDBC',
        colorElements: '#000000',
        properties: [
          {
            rgb: '255 / 237 / 204',
          },
          {
            cmyk: '0 / 7 / 20 / 0'
          }
        ]
      }
    ]
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="ZIQQI" keywords={[`ziqqi`, `application`, `react`]} />
    <SubMenu list={subMenuList} backgroundColor="black-background"/>
    <BrandHeader />
    <BrandIntro class="brand-intro intro" subtitle="Creating the identity for Africa's one-stop online shopping platform." text="ZIQQI brings Africa the realible one-stop online shopping experience that's been missing thus far, offering the widest variety of products in the most-sought-after categories, including electronics, fashion, health &amp; beauty." value="Introduction"/>
    <BrandVideos value="Videos"/>      
    <section data-intersect data-intersect-color="white">
      <Fullscreen url="full-screen-brand-image" alt="Brand Image"/>
    </section>  
    <BrandPhilosophy value="Philosophy"/>
    <BrandSymbol value="Symbol"/>
    <BrandLogoContainer />
    <ColorPalette palettes={palettesGroup} value="Colors"/>
    <Typography value="Typography"/>
    <ZiqqiWallet value="ZIQQI Wallet"/>
    <PreFooter subBrands={subBrands}/>
    <Footer />
  </Layout>
)

export default IndexPage 