import React from 'react'
import { TweenMax, Expo } from 'gsap';

export default class Video extends React.Component{
    constructor(props){
        super()

        this.state = {
            isPlaying: false,
            pageVideosOffset: 0,
            pageVideosHeight: 0
        }
        this.direction = '';
        this.lastScroll = 0;
        this.playVideo = this.playVideo.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.blur = this.blur.bind(this);
        this.focus = this.focus.bind(this);
        this.enter = this.enter.bind(this);
        this.leave = this.leave.bind(this);

        this.handleScroll = this.handleScroll.bind(this);
        this.offsetTop = 0;
        this.inViewport = false;
    }


    componentDidMount(){
        const that = this;
        const videoElement = document.querySelector(".big-video-container");
        this.offsetTop = document.querySelector(".big-video-container").getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('blur', this.blur);
        window.addEventListener('focus', this.focus);
        videoElement.addEventListener("mouseenter", this.enter);
        videoElement.addEventListener("mouseleave", this.leave);
        if(document.querySelector(".page-videos-container .big-video-container").length !== 0){
            setTimeout(function(){
                that.setState({
                    pageVideosOffset: document.querySelector(".page-videos-container .big-video-container").offsetTop,
                    pageVideosHeight: document.querySelector(".page-videos-container .big-video-container").offsetHeight
                });
            }, 1000)
        }

        TweenMax.set(".big-video-container .play-video-button", {
            scale: 0.8, 
            opacity: 0
        });
    }

    enter(){
        const playBtn = document.querySelector(".play-video-button");
        TweenMax.to(playBtn, 0.6, {autoAlpha: 1, ease: Expo.easeOut});
    }

    leave(){
        const playBtn = document.querySelector(".play-video-button");
        if(this.state.isPlaying === true){
            TweenMax.to(playBtn, 0.6, {autoAlpha: 0, ease: Expo.easeOut});
        }
    }


    blur(){
        if(this.state.isPlaying === true){
            document.querySelector(".video video").pause();
        }
    } 

    focus(){
        if(this.state.isPlaying === true && this.lastScroll + window.innerHeight > this.state.pageVideosOffset && this.lastScroll < this.state.pageVideosOffset + this.state.pageVideosHeight){
            document.querySelector(".video video").play();
        }
    }

    playVideo(){
        const playBtn = document.querySelector(".play-video-button");
        const playIcon = document.querySelector(".play-video-button .play-icon");
        const pauseIcon = document.querySelector(".play-video-button .pause-icon");
        const videoContainer = document.querySelector(".video");
        const video = document.querySelector(".video video");
        const title = document.querySelector(".big-video-container span");
        TweenMax.to(document.querySelector(".poster"), 0.2, {display: "none"})

        if(this.state.isPlaying === false){
            this.setState({
                isPlaying: true
            });
            video.play();
            if(title){
                TweenMax.to([playBtn, title], 0.4, {autoAlpha: 0, ease: Expo.easeOut});
            } else {
                TweenMax.to(playBtn, 0.6, {autoAlpha: 0, ease: Expo.easeOut, onComplete: function(){
                }});
            }
            TweenMax.set(playIcon, {opacity: 0, delay: 0.3});
            TweenMax.set(pauseIcon, {opacity: 1, delay: 0.3});
            videoContainer.classList.add("is-playing");
            window.Howler.mute(true)
        } else {
            this.setState({
                isPlaying: false
            });
            video.pause();
            if(title){
                TweenMax.to(title, 0.6, {autoAlpha: 1, ease: Expo.easeOut});
            }
            TweenMax.to(playBtn, 0.6, {autoAlpha: 1, ease: Expo.easeOut});
            videoContainer.classList.remove("is-playing");
            TweenMax.set(playIcon, {opacity: 1});
            TweenMax.set(pauseIcon, {opacity: 0});
            window.Howler.mute(false)
        }
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;
        if(scrollTop + window.innerHeight > this.state.pageVideosOffset && scrollTop < this.state.pageVideosOffset + this.state.pageVideosHeight && this.state.isPlaying === true){
            document.querySelector(".video video").play();
        } else if ((scrollTop + window.innerHeight < this.state.pageVideosOffset && this.state.isPlaying === true) || (scrollTop > this.state.pageVideosOffset + this.state.pageVideosHeight && this.state.isPlaying === true)){
            document.querySelector(".video video").pause();
        }

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/2 && !this.inViewport){
            this.inViewport = true;
            TweenMax.to(".big-video-container .play-video-button", 0.7, {
                scale: 1, 
                opacity: 1,
                ease: Expo.easeOut
            });

            if(document.querySelector(".big-video-container").classList.contains("mart-video-container")){
                document.querySelector(".mart-video-container").classList.add("js-show");
            }
        }
    }

    componentWillUnmount(){
        const videoElement = document.querySelector(".big-video-container");
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('blur', this.blur);
        window.removeEventListener('focus', this.focus);
        videoElement.removeEventListener("mouseenter", this.enter);
        videoElement.removeEventListener("mouseleave", this.leave);
        this.setState({
            isPlaying: false
        });
    }

    render(){
        return(
            <div className="video block-bg-cover" onClick={this.playVideo}>
                <img className="element-cover poster" src={this.props.posterVideo} alt="Video Poster" />
                <video className="element-cover">
                    <source src={this.props.videoSrcURL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="play-video-button">
                    <svg viewBox="0 0 21.55 24.61" className="play-icon">
                        <path d="M21.1,11.53,1.35.12A.9.9,0,0,0,0,.9V23.71a.9.9,0,0,0,1.35.78L21.1,13.08A.89.89,0,0,0,21.1,11.53Z"/>
                    </svg>
                    <svg viewBox="0 0 22 26" className="pause-icon">
                        <path d="M.8 0h6.4a.8.8 0 0 1 .8.8v24.4a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8V.8A.8.8 0 0 1 .8 0zm14 0h6.4a.8.8 0 0 1 .8.8v24.4a.8.8 0 0 1-.8.8h-6.4a.8.8 0 0 1-.8-.8V.8a.8.8 0 0 1 .8-.8z"/>
                    </svg>
                </div>
            </div>
        )
    }
}