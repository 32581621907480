import React from 'react';
import SubTitle from "../components/sub-title"
import Video from "../components/video"
import VideoMain from "../video/video_main.mp4";
import VideoBeauty from "../video/teaser_beauty.mp4";
import VideoElectronics from "../video/teaser_electronics.mp4";
import VideoSupermarket from "../video/teaser_supermarket.mp4";
import VideoPayments from "../video/teaser_payments.mp4";
import { TweenMax, Expo } from 'gsap';

export default class BrandVideos extends React.Component{
    constructor(props){
        super(props);
        this.handleScroll= this.handleScroll.bind(this);
        this.state = {
            videos: [
                {url: VideoMain, title: "Buy on ziqqi", active: true, poster: "buy-on-ziqqi-thumb.jpg"}, 
                {url: VideoBeauty, title: "Beauty", active: false, poster: "beauty-thumb.jpg"}, 
                {url: VideoElectronics, title: "Electronics", active: false, poster: "electronics-thumb.jpg"},
                {url: VideoSupermarket, title: "Supermarket", active: false, poster: "supermarket-thumb.jpg"},
                {url: VideoPayments, title: "Payments", active: false, poster: "payments-thumb.jpg"}
            ]
        }

        this.offsetTop = 0;
        this.inViewport = false;
        this.clickVideos = this.clickVideos.bind(this);
    }

    componentDidMount(){
        this.offsetTop = document.querySelector(".videos-list-container").getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);

        TweenMax.set(".videos-list-container li", {
            x: window.innerWidth*0.25
        })
    }

    clickVideos(i) {
        const that = this;
        TweenMax.to(document.querySelector('.big-video-wrapper'), 0.2, {opacity: 0, onComplete: function(){
            that.setState(prevState => ({
                videos: prevState.videos.map(
                    (elem, key) => {
                        if(key === i) {
                            return Object.assign(elem, { active: true })
                        } else {
                            return Object.assign(elem, { active: false })
                        }
                    }
                )
            }));
            TweenMax.to(document.querySelector('.big-video-wrapper'), 0.2, {opacity: 1, delay: 0.1});
        }});
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/5 && !this.inViewport){
            this.inViewport = true;
            TweenMax.staggerTo(".videos-list-container li", 0.7, {
                x: 0, 
                ease: Expo.easeOut
            }, 0.1)
        }
    }

    render(){
        return(
            <React.Fragment>
                <section className="brand-videos page-videos-container" data-target data-value={this.props.value} data-intersect data-intersect-color="white">
                    <div className="row expanded">
                        <div className="xxlarge-8 xxlarge-offset-2 medium-10 small-12 xsmall-14 xsmall-offset-1 columns">
                            <SubTitle content="Branded videos to establish tone and present the offer."/>
                        </div>
                        <div className="xxlarge-10 xxlarge-offset-2 medium-12 xsmall-14 xsmall-offset-1 columns">
                            <div className="big-video-container">
                                <div className="big-video-wrapper">
                                    <span>{this.state.videos.map((elem, key) => elem.active === true ? elem.title : null)}</span>
                                    {this.state.videos.map((elem,key) => elem.active === true ? <Video key={key} videoSrcURL={elem.url} posterVideo={`../${elem.poster}`}/> : null)}
                                </div>
                            </div>
                        </div>
                        <div className="xxlarge-3 medium-12 medium-offset-2 xsmall-14 xsmall-offset-1">
                            <ul className="videos-list-container">
                                {this.state.videos.map((elem, i) => (
                                <li key={i} onClick={this.clickVideos.bind(BrandVideos, i)} className={elem.active ? "active" : null}>
                                    <div className="video-thumbnail-container">
                                        <div className="block-bg-cover">
                                            <img className="element-cover" src={`../${elem.poster}`} alt="Video Thumbnail"/>
                                        </div>
                                        {/* <Image filename={`../${elem.poster}`} /> */}
                                    </div>
                                    <div className="video-caption">
                                        <span>Ziqqi</span>
                                        <h3>{elem.title}</h3>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}