import React from 'react'
import ZiqqiWalletBrand from "../images/ziqqi-wallet-brand.svg";
import ZiqqiWalletColored from "../images/ziqqi-wallet-colored.svg";
import ZiqqiWalletWhite from "../images/ziqqi-wallet-white.svg";
import { TweenMax, Expo } from 'gsap';

export default class ZiqqiWallet extends React.Component {

    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.offsetTop = 0;
        this.inViewport = false;
    }

    componentDidMount(){
        this.offsetTop = document.querySelector(".ziqqi-wallet-container").getBoundingClientRect().top;
        window.addEventListener('scroll', this.handleScroll);

        TweenMax.set(".ziqqi-wallet-container img", {
            x: window.innerWidth*0.25
        })
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        if(scrollTop + window.innerHeight > this.offsetTop + window.innerHeight/2 && !this.inViewport){
            this.inViewport = true;
            TweenMax.to(".ziqqi-wallet-container img", 0.7,{
                x: 0,
                ease: Expo.easeOut
            })
        }
    }

    render(){
        return(
            <React.Fragment>
                <section className="ziqqi-wallet-container" data-target data-value={this.props.value} data-intersect data-intersect-color="black-and-white">
                    <div className="row expanded align-center">
                        <div className="xxlarge-12 medium-13 columns">
                            <div className="row expanded">
                                <div className="xxlarge-10 xsmall-16 columns">
                                    <h2>
                                        ZIQQI accepts a wide variety of payments but only the <ZiqqiWalletBrand/> creates value for customers and rewards them for their loyalty. Eventually, it will also work as a stand-alone app.
                                    </h2>
                                    <ul>
                                        <li>ZIQQI.com rewards, points and direct cash backs.</li>
                                        <li>Transfer funds to anyone, for free.</li>
                                        <li>Transfer cash balance in the ZIQQI Wallet to a ZIQQI debit card.</li>
                                    </ul>
                                </div>
                                <div className="xxlarge-4 xxlarge-offset-2 medium-5 medium-offset-1 xsmall-10 xsmall-offset-3 columns">
                                    <img src="ziqqi-wallet-card.png" alt="ZIQQI Wallet Card"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ziqqi-wallet-applications" data-intersect data-intersect-color="white">
                    <div className="row expanded align-center align-middle">
                        <div className="xxlarge-16 columns">
                            <div className="row expanded">
                                <div className="xxlarge-8 columns xsmall-16 pos-rel">
                                    <h2 className="white-version">On colored backgrounds</h2>
                                    <ZiqqiWalletColored />
                                </div>
                                <div className="xxlarge-8 columns xsmall-16 pos-rel">
                                    <h2>On white backgrounds</h2>
                                    <ZiqqiWalletWhite />
                                </div> 
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

