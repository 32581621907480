import React from 'react';
import { TweenMax, Expo } from 'gsap';

const typographyList = [
    {
        name: "Equip Extended",
        slug: "equip",
        author: "Linotype",
        title: "We guarantee that",
        text:  "Equip Extended AaBbCcDdEeFfGg 123456789"
    },
    {
        name: "Fedra Sans Arabic",
        slug: "fedra",
        author: "Typotheque",
        title: "نحن نضمن ذلك",
        text:  "ابجدهوزحطيكلمنسعفصقرشتثخذضظغ"
    }
];

export default class Typography extends React.Component {
    constructor(props){
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.inViewport = false;
        this.fullScreenArray = [];
    }

    componentDidMount(){
        const that = this;
        window.addEventListener('scroll', this.handleScroll);

        document.querySelectorAll(".typography-section ul").forEach(elem => {
            let obj = {};

            obj.elem = elem;
            obj.offsetTop = elem.getBoundingClientRect().top + window.innerHeight/2;
            obj.inViewport = false;

            TweenMax.set([elem.querySelectorAll("li span"), elem.querySelectorAll("li h4")], {
                y: 20, 
                opacity: 0
            })

            that.fullScreenArray.push(obj);
        });
    }

    handleScroll(event){
        let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        if (this.lastScroll - scrollTop < 0) {
            this.direction = 'down';
        } else if (this.lastScroll - scrollTop > 0) {
            this.direction = 'up';
        }
    
        this.lastScroll = scrollTop <= 0 ? 0 : scrollTop;

        for (let index = 0; index < this.fullScreenArray.length; index++) {
            if(scrollTop + window.innerHeight > this.fullScreenArray[index].offsetTop && !this.fullScreenArray[index].inViewport){
                this.fullScreenArray[index].inViewport = true;
                TweenMax.staggerTo(this.fullScreenArray[index].elem.querySelectorAll("li span"), 0.7, {
                    y: 0,
                    opacity: 1,
                    ease: Expo.easeOut
                }, 0.1)
                TweenMax.staggerTo(this.fullScreenArray[index].elem.querySelectorAll("li h4"), 0.7, {
                    y: 0,
                    opacity: 1,
                    ease: Expo.easeOut
                }, 0.15)
            }
        }
    }

    render(){
        return(
            <React.Fragment>
                {typographyList.map((elem,key) => {
                    return(
                        <section className={`typography-section ${elem.slug}-typography`} key={key} data-value={key === 0 ? this.props.value : ''} data-target={key === 0 ? 'true': 'false'} data-intersect data-intersect-color={key === 0 ? 'white' : 'black-and-white'}>
                            <div className="row expanded align-center">
                                <div className="xxlarge-10 medium-12 xsmall-14 columns">
                                    <h2>{elem.name}<span>by</span><strong> {elem.author}</strong></h2>
                                    <h3>{elem.title}</h3>
                                    <ul>   
                                        <li>
                                            <span>{elem.name} Light</span>
                                            <h4>{elem.text}</h4>
                                        </li>
                                        <li>
                                            <span>{elem.name} {(key === 1 ? 'Book' : 'Regular')}</span>
                                            <h4>{elem.text}</h4>
                                        </li>
                                        <li>
                                            <span>{elem.name} Medium</span>
                                            <h4>{elem.text}</h4>
                                        </li>
                                        <li>
                                            <span>{elem.name} Bold</span>
                                            <h4>{elem.text}</h4>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    )
                })}
            </React.Fragment>
            
        )
    }
}
